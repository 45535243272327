import { action, observable } from 'mobx';

import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import accountService from '../services/account/accountService';
import { RegisterInput } from '../services/account/dto/registerInput';
import { RegisterOutput } from '../services/account/dto/registerOutput';
import { RegistrationEmailVerificationInput } from '../services/account/dto/registrationEmailVerificationInput';
import { PasswordResetInput } from '../services/account/dto/passwordResetInput';
import { ChangePasswordInput } from '../services/account/dto/changePasswordInput';

class AccountStore {
  @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();
  @observable registrationOutput!: RegisterOutput;

  @action
  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });
  };

  @action
  public register = async (payload: RegisterInput) => {
    this.registrationOutput = await accountService.register(payload);

    return this.registrationOutput;
  };

  @action
  public resendVerificationToken = async (payload: string) => {
    const result = await accountService.resendVerificationToken(payload);

    return result;
  };

  @action
  public verifyAccount = async (payload: RegistrationEmailVerificationInput) => {
    const result = await accountService.verifyAccount(payload);

    return result;
  };

  @action
  public passwordReset = async (payload: PasswordResetInput) => {
    const result = await accountService.resetPassword(payload);

    return result;
  };

  @action
  public changePassword = async (payload: ChangePasswordInput) => {
    const result = await accountService.changePassword(payload);

    return result;
  };

  @action
  public customerRegister = async (payload: FormData) => {
    payload.set('accessToken', this.registrationOutput?.accessToken);
    const result = await accountService.customerRegister(payload);

    return result;
  };

  @action
  public setRegisterationOut = async (payload: RegisterOutput) => {
    this.registrationOutput = payload;
  };
}

export default AccountStore;
