import { stockOrder } from './../services/stockOrder/dto/stockOrder';
import { action, observable } from 'mobx';
import stockOrderService from '../services/stockOrder/stockOrderService';

class StockOrderStore {
  @observable stockOrders!: stockOrder[];

  @action
  async getStockOrders() {
    let result = await stockOrderService.getStockOrders();

    this.stockOrders = result.items;
  }

  @action
  addStockOrder(stockOrder: stockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  @action
  updateStockOrder(stockOrder: stockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  @action
  replaceStockOrder(stockOrder: stockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  @action
  async getOrderTransactionSummary(payload: any) {
    const result = stockOrderService.getOrderTransactionSummary(payload);

    return result;
  }

  @action
  async sendNewOrder(stockOrder: stockOrder) {
    const result = stockOrderService.sendNewOrder(stockOrder);

    return result;
  }

  @action
  async sendReplaceOrder(stockOrder: stockOrder) {
    const result = stockOrderService.sendReplaceOrder(stockOrder);

    return result;
  }

  @action
  async sendCancelOrderRequest(stockOrder: stockOrder) {
    const result = stockOrderService.sendCancelOrderRequest(stockOrder);

    return result;
  }
}

export default StockOrderStore;
