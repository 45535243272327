import { marketTrade } from './dto/marketTrade';
import http from '../httpService';
import { PagedResultDto } from '../dto/pagedResultDto';

class MarketTradeService {
  public async getMarketTrades(): Promise<PagedResultDto<marketTrade>> {
    let result = await http.get('api/services/app/marketData/getMarketTrades');

    return result.data.result;
  }
}

export default new MarketTradeService();
