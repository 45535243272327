import { L } from "../lib/abpUtility";
import { notification } from "antd";
import axios from "axios";

const qs = require("qs");

declare var abp: any;

const http = axios.create({
  // baseURL: storageService.retrieve("remoteServiceBaseUrl"),
  timeout: 60000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: true,
    });
  },
});

http.interceptors.request.use(
  function (config) {
    if (!!abp.auth.getToken()) {
      config.headers.common["Authorization"] = "Bearer " + abp.auth.getToken();
    }

    config.headers.common[".AspNetCore.Culture"] = abp.utils.getCookieValue(
      "Abp.Localization.CultureName"
    );
    config.headers.common["Abp.TenantId"] =
      abp.multiTenancy.getTenantIdCookie();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message &&
      error.response.data.error.details
    ) {
      notification["error"]({
        key: "error",
        message: error.response.data.error.message,
        description: error.response.data.error.details,
        className: "app-notification-error",
        duration: 3.5,
      });
    } else if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message
    ) {
      notification["error"]({
        key: "error",
        message: "An error occured",
        description: error.response.data.error.message,
        className: "app-notification-error",
        duration: 3.5,
      });
    } else if (!error.response) {
      notification["error"]({
        key: "error",
        message: "",
        description: L("UnknownError"),
        className: "app-notification-error",
        duration: 3.5,
      });
    }

    setTimeout(() => {}, 1000);

    return Promise.reject(error);
  }
);

export default http;
