import * as React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useStores } from '../../hooks/use-stores';

import { isGranted } from '../../lib/abpUtility';

declare var abp: any;

const ProtectedRoute = ({
  path,
  component: Component,
  permission,
  roles,
  render,
  ...rest
}: any) => {
  const {
    sessionStore: {
      currentLogin: { user },
    },
  } = useStores();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!abp.session.userId)
          return (
            <Redirect
              to={{
                pathname: ROUTES.userLogin,
                state: { from: props.location },
              }}
            />
          );

        console.log(roles);

        if (
          (permission && !isGranted(permission)) ||
          (roles && user?.roles?.findIndex((x) => roles.includes(x.toUpperCase())) <= -1)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/exception?type=401',
                state: { from: props.location },
              }}
            />
          );
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
