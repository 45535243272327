import { stockOrder } from './dto/stockOrder';
import http from '../httpService';
import { PagedResultDto } from '../dto/pagedResultDto';
import { TransactionSummaryOutputDto } from './dto/TransactionSummaryOutputDto';

class StockOrderService {
  public async getStockOrders(): Promise<PagedResultDto<stockOrder>> {
    let result = await http.get('/api/services/app/Order/getStockOrders');

    return result.data.result;
  }

  public async getOrderTransactionSummary(payload: any): Promise<TransactionSummaryOutputDto> {
    let result = await http.get('/api/services/app/order/getOrderTransactionSummary', {
      params: payload,
    });

    return result.data.result;
  }

  public async sendNewOrder(stockOrder: stockOrder) {
    let result = await http.post('/api/services/app/order/SendNewOrder', stockOrder);

    return result.data.result;
  }

  public async sendReplaceOrder(stockOrder: stockOrder) {
    let result = await http.post('/api/services/app/order/SendOrderReplaceRequest', stockOrder);

    return result.data.result;
  }

  public async sendCancelOrderRequest(stockOrder: stockOrder) {
    let result = await http.post('/api/services/app/order/sendCancelOrderRequest', stockOrder);

    return result.data.result;
  }
}

export default new StockOrderService();
