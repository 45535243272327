const AppConsts = {
  userManagement: {
    defaultAdminUserName: "admin",
  },
  localization: {
    defaultLocalizationSourceName: "Tradebook",
  },
  authorization: {
    encrptedAuthTokenName: "enc_auth_token",
  },
  // appBaseUrl: , // process.env.REACT_APP_APP_BASE_URL,
  // remoteServiceBaseUrl: "", // process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
  AppEvents: {
    addOrderBook: "addOrderBook",
    addAllOrderBook: "addAllOrderBook",
    deleteOrderBook: "deleteOrderBook",
    updateOrderBook: "updateOrderBook",
    clearAllOrderBook: "clearAllOrderBook",
    onNews: "onNews",
    onNseConnection: "onNseConnection",
    onNotifyUserError: "onNotifyUserError",
    updateMarketStatsSession: "updateMarketStatsSession",
    onMarketTrade: "onMarketTrade",
    addMarketStatistics: "addMarketStatistics",
    onExecutionReport: "onExecutionReport",
    addStockOrder: "addStockOrder",
    updateStockOrder: "updateStockOrder",
    replaceStockOrder: "replaceStockOrder",
    received: "received",
    onOrderTransaction: "onOrderTransaction",
  },

  APPNAME: "Tradebook",

  APP_ROLES: {
    ADMIN: "ADMIN",
    BROKER: "BROKER",
    CUSTOMER: "CUSTOMER",
  },

  PERMISSION: {
    VIEW_USERID_MARKET_WATCH: "VIEW_USERID_MARKET_WATCH",
    TRADE_ALL_ACCOUNTS: "TRADE_ALL_ACCOUNTS",
    VIEW_ADMINSETTING: "VIEW_ADMINSETTING",
    VIEW_ALL_ORDERS: "VIEW_ALL_ORDERS",
    BUY: "BUY",
    SELL: "SELL",
  },
};

export default AppConsts;
