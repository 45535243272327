import { marketTrade } from './../services/marketTrade/dto/marketTrade';
import { action, observable } from 'mobx';
import marketTradeService from '../services/marketTrade/marketTradeService';

class MarketTradeStore {
  @observable marketTrades!: marketTrade[];

  @action
  async getMarketTrades() {
    let result = await marketTradeService.getMarketTrades();

    this.marketTrades = result.items;
  }

  @action
  addMarketTrade(marketTrade: marketTrade) {
    const result = this.marketTrades?.find((obj) => obj.mdEntryID === marketTrade.mdEntryID);

    if (!result) {
      this.marketTrades && this.marketTrades.splice(0, 0, marketTrade);
    }
  }

  @action
  deleteAll() {
    this.marketTrades = [];
  }
}

export default MarketTradeStore;
