import "./globals";

import "./index.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import Utils from "./utils/utils";
import abpUserConfigurationService from "./services/abpUserConfigurationService";
import initializeStores from "./stores/storeInitializer";
import registerServiceWorker from "./registerServiceWorker";
import { stores, StoresProvider } from "./contexts";

declare var abp: any;

Utils.setLocalization();

abpUserConfigurationService.load().then(() => {
  abpUserConfigurationService.getAll().then((data) => {
    Utils.extend(true, abp, data.data.result);
    abp.clock.provider = Utils.getCurrentClockProvider(
      data.data.result.clock.provider
    );

    moment.locale(abp.localization.currentLanguage.name);

    if (abp.clock.provider.supportsMultipleTimezone) {
      //TODO
      //moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
    }

    const storesInjected = initializeStores();

    ReactDOM.render(
      <Provider {...storesInjected}>
        <StoresProvider value={stores}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StoresProvider>
      </Provider>,
      document.getElementById("root") as HTMLElement
    );

    registerServiceWorker();
  });
});
