import './App.css';

import React, { useEffect, useState } from 'react';

import Router from './components/Router';

import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper';
import { useStores } from './hooks/use-stores';

import 'react-base-table/styles.css';

import { ModalProvider } from './contexts';
import Loading from './components/Loading';

const App = () => {
  const { sessionStore, marketDataStore, authenticationStore } = useStores();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await sessionStore!.getCurrentLoginInformations();
      if (
        !!sessionStore!.currentLogin.user &&
        sessionStore!.currentLogin.application.features['SignalR.AspNetCore']
      ) {
        SignalRAspNetCoreHelper.initSignalR();
      }

      if (authenticationStore.isAuthenticated) {
        await marketDataStore.getSecurityLists().catch((err) => {
          console.log(err);
        });
      }
      setLoading(false);
    })();
  }, []);

  if (loading) return <Loading />;

  return (
    <div>
      <ModalProvider value={'light'}>
        <Router />
      </ModalProvider>
    </div>
  );
};

export default App;
