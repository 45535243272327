import { securityListDto } from './../services/marketData/dto/securityList';
import cleanDeep from 'clean-deep';
import { action, observable } from 'mobx';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { marketStat } from '../services/marketData/dto/marketStat';
import marketDataService from '../services/marketData/marketDataService';

class MarketDataStore {
  @observable marketStats!: PagedResultDto<marketStat>;
  @observable priceLimitRange!: string;
  @observable securityLists!: PagedResultDto<securityListDto>;

  @action
  async getMarketStats(): Promise<PagedResultDto<marketStat>> {
    let result = await marketDataService.getMarketStat();

    this.marketStats = result;

    return result;
  }

  @action
  async getSecurityLists(): Promise<PagedResultDto<securityListDto>> {
    let result = await marketDataService.getSecurityLists();

    this.securityLists = result;

    return result;
  }

  @action
  addMarketStatistics(marketStat: any): PagedResultDto<marketStat> {
    if (this.marketStats) {
      let index = this.marketStats.items.findIndex(
        (obj: any) => obj.securityID === marketStat.securityID
      );

      if (index !== -1) {
        const marketStatClean = cleanDeep(marketStat);

        let updateRow = { ...this.marketStats.items[index], ...marketStatClean };

        this.marketStats.items[index] = updateRow;
      }
    }

    return this.marketStats;
  }

  @action //TODO updateMarketStatsSession
  async updateMarketStatsSession(data: any) {}

  @action
  async getPricelimitRange(security: string) {
    let result = await marketDataService.getPricelimitRange(security);

    this.priceLimitRange = result;
  }
}

export default MarketDataStore;
