import http from "./httpService";
import axios from "axios";

import storageService from "./storageService/storageService";
class AbpUserConfigurationService {
  public async load() {
    const baseURI = "/";
    let url = `${baseURI}Home/Configuration`;

    const result = await axios.get(url);

    if (result) {
      const { appBaseUrl, remoteServiceBaseUrl } = result.data;
      storageService.store("appBaseUrl", appBaseUrl);
      storageService.store("remoteServiceBaseUrl", remoteServiceBaseUrl);
      http.defaults.baseURL = remoteServiceBaseUrl;
    }
  }
  public async getAll() {
    const result = await http.get("/AbpUserConfiguration/GetAll");
    return result;
  }
}

export default new AbpUserConfigurationService();
