import { action, observable } from 'mobx';
import { marketOrder } from '../services/marketOrder/dto/marketOrder';
import marketOrderService from '../services/marketOrder/marketOrderService';
import * as _ from 'lodash';

class MarketOrderStore {
  @observable sellOrders: marketOrder[] = [];
  @observable buyOrders: marketOrder[] = [];

  @observable isBuyLoading: boolean = false;
  @observable isSellLoading: boolean = false;

  @observable selectedSecurityId: string = '';

  @action
  setSelectedSecurityId(selectedSecurityId: string) {
    this.selectedSecurityId = selectedSecurityId;
  }

  @action
  addOrderBook(orderbook: marketOrder) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== orderbook.securityID) return;

    if (orderbook.mdEntryType === '0') {
      let isBuyOrderExists = this.buyOrders.find((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (!isBuyOrderExists) {
        let orders = this.buyOrders;

        orders.push(orderbook);

        this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      }
    } else if (orderbook.mdEntryType === '1') {
      let isSellOrderExists = this.sellOrders.find((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (!isSellOrderExists) {
        let orders = this.sellOrders;
        orders.push(orderbook);

        this.sellOrders = this.calculateTotalOrders(orders, 'asc');
      }
    }
  }

  @action
  async getAllOrderBook(security: string, side: string) {
    if (side === '0') {
      this.isBuyLoading = true;
    } else {
      this.isSellLoading = true;
    }

    let marketOrders = await marketOrderService.getMarketOrders(security, side);

    if (side === '0') {
      this.buyOrders = this.calculateTotalOrders(marketOrders, 'desc');
      this.isBuyLoading = false;
    } else {
      this.sellOrders = this.calculateTotalOrders(marketOrders, 'asc');
      this.isSellLoading = false;
    }
  }

  @action
  deleteOrderBook(mDEntryID: string) {
    console.log(mDEntryID);
    let index1 = this.buyOrders.findIndex((obj) => obj.mdEntryID === mDEntryID);

    if (index1 > -1) {
      let orders = this.buyOrders;

      orders.splice(index1, 1);

      this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      return;
    }

    let index2 = this.sellOrders.findIndex((obj) => obj.mdEntryID === mDEntryID);

    if (index2 > -1) {
      let orders = this.sellOrders;

      orders.splice(index2, 1);

      this.sellOrders = this.calculateTotalOrders(orders, 'asc');
    }
  }

  @action
  updateOrderBook(orderbook: marketOrder) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== orderbook.securityID) return;

    if (orderbook.mdEntryType === '0') {
      let index = this.buyOrders.findIndex((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (index > -1) {
        let orders = this.buyOrders;
        orders[index] = orderbook;

        this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      }
    } else if (orderbook.mdEntryType === '1') {
      let index = this.sellOrders.findIndex((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (index > -1) {
        let orders = this.sellOrders;
        orders[index] = orderbook;

        this.sellOrders = this.calculateTotalOrders(orders, 'asc');
      }
    }
  }

  @action
  clearAllOrderBook(security: string) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== security) return;

    this.buyOrders = [];
    this.sellOrders = [];
  }

  calculateTotalOrders = function (orders: marketOrder[], sortDir: 'asc' | 'desc') {
    let qty = 0;

    let newOrders: any = [];

    let sortedOrders: any[] = orders.slice();

    sortedOrders = _.orderBy(
      sortedOrders,
      ['mdEntryPx', 'mdEntryTime', 'mdEntryPositionNo'],
      [sortDir]
    );

    sortedOrders.forEach(function (item) {
      qty += item.mdEntrySize;
      item.totalQuantity = qty;
      newOrders.push(item);
    });

    return newOrders;
  };
}

export default MarketOrderStore;
