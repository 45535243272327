import { reportEventLog } from './../services/reportEventLogs/dto/reportEventLog';

import { action, observable } from 'mobx';
import reportEventLogService from '../services/reportEventLogs/reportEventLogService';

class ReportEventLogStore {
  @observable reportEventLog!: reportEventLog[];

  @action
  async getReportEventLogs() {
    let result = await reportEventLogService.getReportEventLogs();

    this.reportEventLog = result;
  }

  @action
  addReport(report: reportEventLog) {
    this.reportEventLog.splice(0, 0, report);
  }
}

export default ReportEventLogStore;
