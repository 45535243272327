import { action, observable } from 'mobx';
import marketNewsService from '../services/marketNews/marketNewsService';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { news } from '../services/marketNews/dto/news';

class MarketNewsStore {
  @observable marketNews!: PagedResultDto<news>;

  @action
  async getMarketNews() {
    let result = await marketNewsService.getNews();

    this.marketNews = result;
  }

  @action
  addMarketNews(marketNews: news) {
    this.marketNews.items.splice(0, 0, marketNews);
  }
}

export default MarketNewsStore;
