import { securityListDto } from './dto/securityList';
import { PagedResultDto } from './../dto/pagedResultDto';
import http from '../httpService';
import { marketStat } from './dto/marketStat';
import { marketTrade } from '../marketTrade/dto/marketTrade';

class MarketDataService {
  public async getMarketStat(): Promise<PagedResultDto<marketStat>> {
    let result = await http.get('api/services/app/MarketData/GetMarketStats');

    return result.data.result;
  }

  public async getSecurityLists(): Promise<PagedResultDto<securityListDto>> {
    let result = await http.get(`/api/services/app/MarketData/getSecurityLists`);

    return result.data.result;
  }

  public async getPricelimitRange(security: string): Promise<string> {
    let result = await http.get(
      `/api/services/app/MarketData/getPricelimitRange/?security=${security}`
    );

    return result.data.result;
  }

  public async getTopStatistics(asc?: boolean): Promise<marketStat> {
    let result = await http.get(`api/services/app/MarketData/GetTopStatistics`, {
      params: { asc },
    });

    return result.data.result;
  }

  public async getTopTrades(): Promise<marketTrade> {
    let result = await http.get(`api/services/app/MarketData/GetTopTrades`);

    return result.data.result;
  }

  public async getPriceTrend(security: string): Promise<any> {
    let result = await http.get(`api/services/app/MarketData/GetPriceTrend`, {
      params: {
        security,
      },
    });

    return result.data.result;
  }
}

export default new MarketDataService();
